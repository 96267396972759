const conditionForm = {
    state: {

        conditionsOnForm: [],

    },

    getters: {


        getConditionForm: (state) => (id) => {
            return state.conditionsOnForm.find(specific => specific.page === id)

        },
    },

    mutations: {


        changeConditionForm(state, params) {


            let cond = state.conditionsOnForm.find(x => x.page == params.page).conditions

            // on s'occupe des remove :
            for (const keyField in params.datas) {
                params.datas[keyField].remove.forEach(removeitem => {
                    cond.forEach(item => {
                        item[keyField] = (item[keyField]) ? item[keyField].filter(item2 => item2.key != removeitem.key) : item[keyField];
                    })
                })
                params.datas[keyField].insert.forEach(insertItem => {
                    var find = false
                    cond.forEach(item => {
                        if (item[keyField]) {
                            find = true
                            item[keyField] = item[keyField].filter(item2 => item2.key != insertItem.key);
                            item[keyField].push(insertItem);
                        }

                    })
                    let insert = {};
                    insert[keyField] = params.datas[keyField].insert;
                    if (!find) cond.push(insert)
                })
            }

        },

        initConditionForm(state, params) {


            let newArray = { page: params.page, conditions: [] };

            state.conditionsOnForm = state.conditionsOnForm.filter((x) => { x.page != params.page })

            state.conditionsOnForm.push(newArray);
        }


    },

    actions: {
        // on met le scripte directement dans l'action car on peut appeler une autre action dans l'action. pas possible dans les mutations

        changeConditionForm({ commit }, params) {
            commit('changeConditionForm', params)
        },


        initConditionForm({ commit }, params) {
            commit('initConditionForm', params)
        }


    }

}
export default conditionForm;