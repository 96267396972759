<!-- 
Ce composant permet d'avoir les informations initiales obligatoires pour créer un module. 
Dupliquez ce composant pour créer un nouveau module
-->

<template>

    <div>
    <not-allowed v-if="!AllowedDisplay"></not-allowed>
        <v-scale-transition>    
            <div v-show="show">
                essai
            </div>
        </v-scale-transition>
    </div>

</template>



<script>


    export default {

    name :'PlanningModule',

    props: ['idmodule','page','modal','parent','customparams','customquerys'],

    components : {
 
        },

        data: function() {

            return {

                show : false,
                AllowedDisplay : true,
                params:{},
                querys:{},
            }
        },

        methods :  {
 

        },
        mounted() {
                this.show = true;
                var modal = this.$store.getters.modalShowParams({ type: 'to', val: this.page })
                this.params = (this.modal && modal.show) ? modal.params : this.$route.params
                this.querys = (this.modal && modal.show) ? modal.query : this.$route.query
                this.params = (this.customparams) ? this.customparams : this.params
                this.querys = (this.customquerys) ? this.customquerys : this.querys
 
        }

    }

</script>