var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.APILoaded)?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('modal-template',{attrs:{"pageTemplate":_vm.page,"main":false,"visible":_vm.showModal}}),_c('v-row',[_c('v-col',{staticClass:"col-md-4"},[_c('Select',{attrs:{"item":{
          name: 'Type de module',
          value: 'type',
          liste: [
            { text: 'table', value: 'table' },
            { text: 'formulaire', value: 'form' },
            { text: 'recherche', value: 'search' },
            { text: 'calendrier', value: 'calendar' },
            { text: 'tabulation', value: 'tabs' },
            { text: 'pdf', value: 'pdf' },
            { text: 'Bilbiotheque d\'image', value: 'bibliotheque' },
            { text: 'Envoi d\'email', value: 'sendemail' },
            { text: 'historique d\'email', value: 'historiqueemail' },
            { text: 'Front datas', value: 'frontdatas' },
            { text: 'GED', value: 'ged' },
            { text: 'planning', value: 'planning' },
            { text: 'Ventilation', value: 'ventilation' },
          ],
          defaultprops: {
            show: true,
            modelRules: [(v) => !!v || 'Ce champ doit etre renseigné'],
            disabled: _vm.module.id ? true : false,
          },
        },"valField":_vm.module.type,"change":_vm.changeParam}}),_c('Autocomplete',{attrs:{"item":{
          name: 'table',
          value: 'table',
          liste: _vm.itemTableListe,
          defaultprops: {
            show: true,
            chips: false,
            deletablechips: false,
            modelRules: [(v) => !!v || 'Ce champ doit etre renseigné'],
            disabled: _vm.module.id ? true : false,
          },
        },"valField":_vm.module.table,"change":_vm.changeParam}}),_c('TextField',{attrs:{"keyup":_vm.changeParam,"valField":_vm.module.name,"item":{
          name: 'Nom du module',
          value: 'name',
          defaultprops: { show: true, modelRules: [], class: 'pa-0 ma-0' },
        }}}),_c('TextArea',{attrs:{"keyup":_vm.changeParam,"valField":_vm.module.description,"item":{
          name: 'description',
          value: 'description',
          defaultprops: {
            show: true,
            modelRules: [],
            class: 'pa-0 ma-0',
            row: 1,
            filled: true,
            autogrow: true,
          },
        }}}),(_vm.module.type != '' && _vm.module.table != '')?_c('div',[_c('v-btn',{on:{"click":function($event){_vm.openSaveTemplate = !_vm.openSaveTemplate}}},[_vm._v("Templates de module")]),(_vm.APILoaded)?_c('loadSaveTemplate',{attrs:{"page":_vm.page,"openModal":_vm.openSaveTemplate,"type":"moduleparams","templateToSave":_vm.$store.getters.listForm(_vm.page)['fields']['displays']},on:{"closeLST":() => {
              _vm.openSaveTemplate = !_vm.openSaveTemplate;
            },"loadTemplate":_vm.loadTemplate}}):_vm._e()],1):_vm._e()],1),_c('v-col',{staticClass:"col-md-8"},[_c('v-tabs',{attrs:{"align":"start","background-color":"blue lighten-5"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" défaut ")]),(this.module.id)?_c('v-tab',[_vm._v(" par compagnie ")]):_vm._e(),_c('v-tabs-items',{staticClass:"pa-1",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[(_vm.APILoaded)?_c(_vm.type,{key:'r' + _vm.reloadComp,tag:"component",attrs:{"module":_vm.module,"page":_vm.page,"itemTableListe":_vm.itemTableListe},on:{"recepVal":_vm.changeDisplay}}):_vm._e()],1),(this.module.id)?_c('v-tab-item',_vm._l((_vm.listEntreprises),function(etps,i){return _c('v-row',{key:i,attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(_vm._s(etps.nom))]),_c('v-col',[_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.goTo({
                        rootPage: _vm.page,
                        type: 'modal',
                        page: 'editvariantmodule',
                        replace: { id: _vm.module.id, variant: etps.id },
                        paramDialog: { width: 1000 },
                      })}}},[(_vm.module.listvariant.includes(etps.id))?_c('v-icon',[_vm._v("mdi-pencil")]):_c('v-icon',[_vm._v("mdi-plus")])],1)],1)])],1)}),1):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-12 text-center"},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.valid},on:{"click":_vm.validate}},[_vm._v("Enregistrer")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }