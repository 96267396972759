<template>
  <div>
    <v-container v-if="checkDroits">
      <v-row>
        <v-col class="text-h5 text-center">Gestion des Bases de Données</v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8">
          <v-textarea
            placeholder="Créé un logiciel pour gérer le suivi de ma clientèle et des commerciaux ..."
            label="instructions pour créer le logiciel"
            v-model="inputData"
            outlined
            dense
            rows="4"
            auto-grow
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn @click="submitAIData" color="primary"
            >Générer les tables par l'IA</v-btn
          >
        </v-col>
      </v-row>

      <v-tabs>
        <v-tab>Créer une BDD entreprise</v-tab>
        <v-tab>Créer un Métier</v-tab>
        <v-tab>CRÉER DE NOUVELLES TABLES</v-tab>

        <v-tab-item>
          <v-row>
            <v-col>
              <div class="text-h5">Créer une BDD entreprise :</div>
              <v-form v-model="validForm" ref="databaseForm">
                <v-row>
                  <v-col>
                    <TextField
                      :item="{
                        value: 'entrepriseName',
                        name: 'Nom de l\'entreprise',
                        defaultprops: {
                          show: true,
                          modelRules: [
                            (v) => !!v || 'Ce champ ne doit pas être vide',
                          ],
                          outlined: true,
                          required: true,
                        },
                      }"
                      :keyup="keyUp"
                      :valField="form.entrepriseName"
                    />
                    <Select
                      :change="change"
                      :item="{
                        name: 'Métier attribué',
                        value: 'databaseType',
                        liste: databaseTypes,
                        defaultprops: {
                          show: true,
                          required: true,
                          modelRules: [
                            (v) => !!v || 'Ce champ ne doit pas être vide',
                          ],
                        },
                      }"
                      :valField="form.databaseType"
                    />

                    <v-btn @click="createDatabaseEnt"
                      >Créer la Base de Données</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>

              <div v-if="progressVisible">
                <div>Étape: {{ step }}</div>
                <v-progress-linear :indeterminate="true"></v-progress-linear>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col>
              <div class="text-h5">Créer un Métier :</div>
              <v-form v-model="validFormMetier" ref="metierForm">
                <v-row>
                  <v-col>
                    <TextField
                      :item="{
                        value: 'metierName',
                        name: 'Nom du métier',
                        defaultprops: {
                          show: true,
                          modelRules: [
                            (v) => !!v || 'Ce champ ne doit pas être vide',
                          ],
                          outlined: true,
                          required: true,
                        },
                      }"
                      :keyup="keyUpMetier"
                      :valField="formMetier.metierName"
                    />
                    <v-btn @click="createMetier">Créer le Métier</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col>
              <div v-if="diagramCode" ref="mermaidContainer" class="mermaid">
                {{ diagramCode }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="text-h5">
                CRÉER DE NOUVELLES TABLES dans le métier :
              </div>

              <v-form
                @submit.prevent="handleMigrationSubmit"
                v-model="validFormMigration"
                ref="migrationForm"
              >
                <v-row v-for="(table, index) in tables" :key="index">
                  <v-col>
                    <v-text-field
                      v-model="table.name"
                      label="Nom de la table"
                      :rules="[
                        (v) => !!v || 'Ce champ ne doit pas être vide',
                        (v) =>
                          !existingTablesFromBackend.includes(v) ||
                          'Le nom de la table existe déjà',
                      ]"
                      required
                      outlined
                    ></v-text-field>
                    <v-btn @click.prevent="removeTable(index)" color="red"
                      >Supprimer la table</v-btn
                    >

                    <v-btn @click.prevent="addRelation(index)"
                      >Ajouter une relation</v-btn
                    >

                    <v-row
                      v-for="(relation, relIndex) in table.relations"
                      :key="relIndex"
                    >
                      <v-col cols="2">
                        <v-select
                          v-model="relation.type"
                          :items="relationTypes"
                          label="Type de relation"
                          required
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="2">
                        <v-select
                          v-model="relation.table"
                          :items="existingTables"
                          label="Table liée"
                          required
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="relation.foreign_key"
                          label="Clé étrangère"
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="relation.references"
                          label="Référence (clé de la table liée)"
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-select
                          v-model="relation.on_delete"
                          :items="['cascade', 'restrict', 'set null']"
                          label="Action à la suppression"
                          required
                          outlined
                        ></v-select>
                      </v-col>
                      <v-btn
                        @click.prevent="removeRelation(index, relIndex)"
                        color="red"
                      >
                        Supprimer la relation
                      </v-btn>
                    </v-row>

                    <v-btn @click.prevent="addColumn(index)"
                      >Ajouter une colonne</v-btn
                    >
                    <v-row>
                      <v-col
                        v-for="(column, colIndex) in table.columns"
                        :key="colIndex"
                        cols="2"
                        sm="2"
                        md="2"
                        class="d-flex"
                      >
                        <v-card class="pa-2 flex-grow-1">
                          <v-btn
                            icon
                            small
                            @click="removeColumn(index, colIndex)"
                            class="mb-2"
                            color="red"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-text-field
                            v-model="column.name"
                            label="Nom de la colonne"
                            :rules="[
                              (v) => !!v || 'Ce champ ne doit pas être vide',
                              (v) =>
                                !isDuplicateColumnName(index, v) ||
                                'Ce nom de colonne existe déjà dans cette table',
                            ]"
                            required
                            outlined
                          ></v-text-field>
                          <v-select
                            v-model="column.type"
                            :items="columnTypes"
                            label="Type"
                            :rules="[
                              (v) => !!v || 'Ce champ ne doit pas être vide',
                            ]"
                            required
                            outlined
                          ></v-select>
                          <v-checkbox
                            v-model="column.nullable"
                            label="Nullable"
                            outlined
                          ></v-checkbox>
                          <v-text-field
                            v-model="column.default"
                            label="Valeur par défaut"
                            outlined
                          ></v-text-field>
                          <!-- Ajout des nouvelles propriétés -->
                          <v-checkbox
                            v-model="column.primary_key"
                            label="Clé primaire"
                            outlined
                          ></v-checkbox>
                          <v-checkbox
                            v-model="column.unique"
                            label="Unique"
                            outlined
                          ></v-checkbox>
                          <v-checkbox
                            v-model="column.index"
                            label="Indexer cette colonne"
                            outlined
                          ></v-checkbox>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-btn @click.prevent="addTable">Ajouter une autre table</v-btn>
                <v-btn type="submit">Générer les tables</v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
      <v-row>
        <v-col>
          <div v-if="progressVisibleMetier">
            <div>Étape: {{ stepMetier }}</div>
            <v-progress-linear :indeterminate="true"></v-progress-linear>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <v-row>
        <v-col>
          {{ isAllow }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import mermaid from "mermaid";
export default {
  name: "manage-bdd",
  props: ["page", "modal"],
  components: {
    TextField: () => import("../fields/textfield.vue"),
    Select: () => import("../fields/select.vue"),
  },

  data: function () {
    return {
      diagramCode: "",
      validForm: false,
      validFormMetier: false,
      validFormMigration: false,
      isAllow: "En attente du module",
      checkDroits: false,
      form: {
        entrepriseName: "",
        databaseType: "",
      },
      formMetier: {
        metierName: "",
      },
      databaseTypes: [],
      progressVisible: false,
      progressVisibleMetier: false,
      step: "",
      stepMetier: "",
      tables: [],
      columnTypes: [
        "string",
        "text",
        "unsignedBigInteger",
        "datetime",
        "decimal",
      ],

      relationTypes: [
        "one-to-one",
        "one-to-many",
        "many-to-many",
        "many-to-one",
      ],
      existingTables: [], // Array to hold existing tables
      inputData: "",
      existingTablesFromBackend: [],
    };
  },

  methods: {
    generateDiagramCode() {
      let code = "erDiagram\n";

      // Définir les tables et leurs colonnes
      this.tables.forEach((table) => {
        code += `${table.name} {\n`;
        table.columns.forEach((column) => {
          const isPrimaryKey = column.primary_key ? "PK" : "";
          const isUnique = column.unique ? "unique" : "";
          const nullable = column.nullable ? "nullable" : "not null";
          code += `  ${column.type} ${column.name} "${nullable} ${isPrimaryKey} ${isUnique}"\n`;
        });
        code += "}\n";
      });

      // Définir les relations entre les tables
      this.tables.forEach((table) => {
        table.relations.forEach((relation) => {
          // Déterminer le type de relation pour Mermaid.js
          let relationType;
          switch (relation.type) {
            case "one-to-one":
              relationType = "||--||";
              break;
            case "one-to-many":
              relationType = "||--o{";
              break;
            case "many-to-one":
              relationType = "}o--||";
              break;
            case "many-to-many":
              relationType = "}o--o{";
              break;
            default:
              relationType = "--";
          }

          code += `${relation.table} ${relationType} ${table.name} : "${relation.foreign_key} → ${relation.references}"\n`;
        });
      });

      // Assigner le code généré à diagramCode et initialiser Mermaid.js
      this.diagramCode = code;
      console.log("Generated Mermaid Code:", code); // Pour vérifier le code généré
      this.$nextTick(() => {
        mermaid.init(undefined, this.$refs.mermaidContainer);
      });
    },
    submitAIData() {
      this.API({
        type: "post",
        url: "/aide-ai",
        params: {
          data: this.inputData,
        },
      })
        .then((response) => {
          if (response.data.success) {
            // Remplacer complètement les tables
            this.tables = response.data.tables; // Remplacement des tables
            this.updateExistingTablesList();
            this.generateDiagramCode();
            this.$toast.success(
              "Données envoyées avec succès ! " + response.data.threadId
            );
          } else {
            this.$toast.error("Erreur lors de l'envoi des données.");
          }
        })
        .catch((error) => {
          this.$toast.error("Erreur : " + error.message);
        });
    },
    updateExistingTablesList() {
      // Récupérer les noms des tables dans le formulaire (mais ne les ajouter pas à existingTablesFromBackend)
      // Récupérer les noms des tables saisies dans le formulaire
      const tableNamesFromForm = this.tables
        .map((table) => table.name)
        .filter((name) => !!name);

      // Combiner avec les tables existantes et inclure les tables saisies dans le formulaire
      this.existingTables = [
        ...new Set([...this.existingTablesFromBackend, ...tableNamesFromForm]),
      ];

      console.log(tableNamesFromForm);
    },

    // Ajouter la nouvelle table sans toucher à la liste des tables existantes du backend
    // Méthode pour ajouter une nouvelle table
    addTable() {
      this.tables.push({
        name: "",
        columns: [
          {
            name: "",
            type: "string",
            nullable: false,
            default: "",
            primary_key: false,
            unique: false,
            index: false,
          },
        ],
        relations: [],
        errors: [],
      });

      // Mettre à jour la liste des tables après l'ajout
      this.updateExistingTablesList();
    },

    // Méthode pour retirer une table et mettre à jour la liste
    removeTable(tableIndex) {
      this.tables.splice(tableIndex, 1);
      // Mettre à jour la liste des tables après suppression
      this.updateExistingTablesList();
    },
    keyUp(item, value) {
      this.form[item.value] = value;
    },

    keyUpMetier(item, value) {
      this.formMetier[item.value] = value;
    },

    change(item, model, selectedKey) {
      this.form[item.value] = model[selectedKey];
    },
    removeColumn(tableIndex, columnIndex) {
      this.tables[tableIndex].columns.splice(columnIndex, 1);
    },

    // Ajout d'une relation
    addRelation(tableIndex) {
      this.tables[tableIndex].relations.push({
        type: "",
        table: "",
        foreign_key: "",
        references: "",
        on_delete: "cascade",
      });
    },

    // Suppression d'une relation
    removeRelation(tableIndex, relationIndex) {
      this.tables[tableIndex].relations.splice(relationIndex, 1);
    },

    createDatabaseEnt() {
      if (this.$refs.databaseForm.validate()) {
        this.progressVisible = true;
        this.step = "Création de la base de données";

        this.API({
          type: "post",
          url: "/create-database",
          params: {
            entreprise: this.form.entrepriseName,
            type: this.form.databaseType,
          },
        })
          .then((resp) => {
            if (resp.data.success) {
              this.step = "Exécution des migrations et des seeders";
              this.runMigrationsAndSeeders(
                resp.data.databaseName,
                resp.data.metier
              );
            } else {
              this.$toast.error(
                "Erreur lors de la création de la base de données."
              );
              this.progressVisible = false;
            }
          })
          .catch((error) => {
            this.$toast.error("Erreur : " + error.message);
            this.progressVisible = false;
          });
      }
    },

    runMigrationsAndSeeders(databaseName, metier) {
      this.API({
        type: "post",
        url: "/run-migrations-and-seeders",
        params: {
          type: metier,
          database: databaseName,
        },
      })
        .then((resp) => {
          if (resp.data.success) {
            this.$toast.success("Migrations et seeders exécutés avec succès !");
            this.progressVisible = false;
            window.location.href = "/";
          } else {
            this.$toast.error(
              "Erreur lors de l'exécution des migrations et des seeders."
            );
            this.progressVisible = false;
          }
        })
        .catch((error) => {
          this.$toast.error("Erreur : " + error.message);
          this.progressVisible = false;
        });
    },

    createMetier() {
      if (this.$refs.metierForm.validate()) {
        this.progressVisibleMetier = true;
        this.stepMetier = "Création du métier";

        this.API({
          type: "post",
          url: "/create-metier",
          params: {
            metier: this.formMetier.metierName,
          },
        })
          .then((resp) => {
            if (resp.data.success) {
              this.$toast.success("Métier créé avec succès !");
              this.runMigrationsAndSeedersMetier(resp.data.metierName);
            } else {
              this.$toast.error("Erreur lors de la création du métier.");
              this.progressVisibleMetier = false;
            }
          })
          .catch((error) => {
            this.$toast.error("Erreur : " + error.message);
            this.progressVisibleMetier = false;
          });
      }
    },

    runMigrationsAndSeedersMetier(metierName) {
      this.progressVisibleMetier = true;
      this.stepMetier =
        "Exécution des migrations et des seeders pour le métier";

      this.API({
        type: "post",
        url: "/run-migrations-and-seeders-metier",
        params: {
          metierName: metierName,
        },
      })
        .then((resp) => {
          if (resp.data.success) {
            this.$toast.success(
              "Migrations et seeders pour le métier exécutés avec succès !"
            );
            this.createDatabaseDemo(metierName);
          } else {
            this.$toast.error(
              "Erreur lors de l'exécution des migrations et des seeders pour le métier."
            );
            this.progressVisibleMetier = false;
          }
        })
        .catch((error) => {
          this.$toast.error("Erreur : " + error.message);
          this.progressVisibleMetier = false;
        });
    },

    createDatabaseDemo(metierName) {
      this.progressVisibleMetier = true;
      this.stepMetier =
        "Création de l'entreprise 'demo' pour le nouveau métier";

      this.API({
        type: "post",
        url: "/create-database",
        params: {
          entreprise: "demo",
          type: metierName,
        },
      })
        .then((resp) => {
          if (resp.data.success) {
            this.stepMetier = "Exécution des migrations et des seeders";
            this.runMigrationsAndSeeders(
              resp.data.databaseName,
              resp.data.metier
            );
          } else {
            this.$toast.error(
              'Erreur lors de la création de l\'entreprise "demo".'
            );
            this.progressVisibleMetier = false;
          }
        })
        .catch((error) => {
          this.$toast.error("Erreur : " + error.message);
          this.progressVisibleMetier = false;
        });
    },

    addColumn(tableIndex) {
      this.tables[tableIndex].columns.push({
        name: "",
        type: "string",
        nullable: false,
        default: "",
        primary_key: false,
        unique: false,
        index: false,
      });
    },

    isDuplicateColumnName(tableIndex, columnName) {
      const columnNames = this.tables[tableIndex].columns.map(
        (column) => column.name
      );
      return columnNames.filter((name) => name === columnName).length > 1;
    },

    async handleMigrationSubmit() {
      if (this.$refs.migrationForm.validate()) {
        this.progressVisibleMigration = true;
        this.stepMigration = "Génération des migrations";

        this.API({
          type: "post",
          url: "/generate-migrations",
          params: {
            tables: this.tables,
          },
        })
          .then((resp) => {
            if (resp.data.success) {
              this.$toast.success(resp.data.message);
              this.progressVisibleMigration = false;
            } else {
              this.$toast.error(resp.data.message);
              this.progressVisibleMigration = false;
            }
          })
          .catch((error) => {
            this.$toast.error("Erreur : " + error.message);
            this.progressVisibleMigration = false;
          });
      } else {
        this.$toast.error("Veuillez corriger les erreurs avant de soumettre.");
      }
    },

    // Méthode pour charger les tables existantes à partir du backend
    async loadExistingTables() {
      try {
        const response = await this.API({
          type: "post",
          url: "/list-tables",
        });

        // Stocker les tables existantes dans existingTablesFromBackend
        this.existingTablesFromBackend = response.data.tables.map(
          (table) => Object.values(table)[0]
        );

        // Mettre à jour la liste des tables existantes
        this.updateExistingTablesList();
      } catch (error) {
        this.$toast.error(
          "Erreur lors du chargement des tables existantes : " + error.message
        );
      }
    },

    async loadDatabaseTypes() {
      try {
        const response = await this.API({
          type: "post",
          url: "/list-metiers",
        });
        this.databaseTypes = response.data.metiers;
      } catch (error) {
        this.$toast.error(
          "Erreur lors du chargement des types de base de données : " +
            error.message
        );
      }
    },
  },

  created() {
    // on check si l'utilisateur peut créer une base de données
    this.API({
      type: "post",
      url: "/engine/droits/getdroit",
      params: { root: "general", core: "managebdd" },
    }).then((resp) => {
      this.checkDroits = resp.data;
    });

    // Load existing tables
    this.loadExistingTables();
    this.loadDatabaseTypes();
  },
};
</script>
<style scoped>
.mermaid {
  margin-top: 20px;
  text-align: center;
}
</style>
