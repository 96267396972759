<template>
  <v-card elevation="2" shaped class="ma-1">
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title>Paramètres du module Formulaire </v-toolbar-title>
    </v-toolbar>
    <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
      <v-tab> Liste des RDV à récupérer </v-tab>
      <v-tab> Liste desfiches par onglet </v-tab>
      <v-tabs-items v-model="tab" class="pl-4">
        <v-tab-item>
          <v-row>
            <v-col class="ma-1">
              <v-btn fab small @click="addVariant"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
              <v-expansion-panels
                variant="popout"
                v-model="accordionEncart"
                class="pa-3"
              >
                <v-expansion-panel
                  v-for="(encart, i) in displays.params.queryList"
                  :key="i"
                >
                
                  <v-expansion-panel-header>
                    {{ encart.key }}
                    <template v-slot:actions>
                      <v-btn
                        icon
                        @click="removeVariantListe(i)"
                        v-if="encart.key != 'default'"
                        ><v-icon>mdi-close-thick</v-icon></v-btn
                      >
                    </template>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="grey lighten-3">
                    <TextField 
                    :item="{name:'nom du variant',value:'key',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {displays.params.queryList[i].key = value}"
                    :valField="encart.key" />
                    <v-tabs
                      align="start"
                      background-color="blue lighten-5"
                      v-model="tab2"
                    >
                      <v-tab> filtre </v-tab>
                      <v-tab> champs </v-tab>
                      <v-tab> Paramétrage de l'encart</v-tab>
                      <v-tabs-items v-model="tab2" class="pl-4">
                        <v-tab-item>
                          <Filtre
                            :filtre="displays.params.queryList[i].filtre"
                            :table="module.table"
                            @recepVal="
                              (array) => {
                                displays.params.queryList[i].filtre = array;
                              }
                            "
                          />
                        </v-tab-item>

                        <v-tab-item>
                          <Fields
                            :table="module.table"
                            :champs="displays.params.queryList[i].champs"
                            :filter="displays.params.queryList[i].filtre"
                            :page="page"
                            @recepVal="
                              (array) => {
                                displays.params.queryList[i].champs = array;
                              }
                            "
                          />
                        </v-tab-item>
                        <v-tab-item>
                            <TextField 
                            :item="{name:'Couleur de bordure',value:'border-color',defaultprops:{show:true}}" 
                            :keyup="(item,value,selectedKey) => {displays.params.queryList[i].paramsEncart.bordercolor = value}"
                            :valField="encart.bordercolor" />
                        </v-tab-item>
                      </v-tabs-items>
                    </v-tabs>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item> tab 2 </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  name: "PlanningModule",
  props: ["module", "page"],

  components: {
    //Autocomplete: () => import("../../fields/autocomplete.vue"),
    //SimpleTable: () => import("../outils/simpleTable.vue"),
    //SwitchC: () => import("../../fields/switch.vue"),
    Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
    Fields: () => import("../outils/constructField/Field.vue"),
    TextField: () => import("../../fields/textfield.vue"),
  },

  data: function () {
    return {
      tab: "",
      tab2: "",
      accordionEncart: "",
      displays: this.module.displays,
    };
  },

  watch: {},

  methods: {
    removeVariantListe(key) {
      this.displays.params.queryList = this.displays.params.queryList.filter(
        (el, i) => i != key
      );
      this.$emit("recepVal", this.displays);
    },

    addVariant() {
      var defaultEncart = this.displays.params.queryList.find(
        (el) => el.key === "default"
      );
      let copieDefault = JSON.parse(JSON.stringify(defaultEncart));
      copieDefault.key = "nouveau variant";
      this.displays.params.queryList.push(copieDefault);
      this.$emit("recepVal", this.displays);
    },

    getFromAPI() {
      this.getListField({ filter: { table: this.module.table } }).then(
        (resp) => {
          this.allFields = resp;
          this.constructArray();
          this.constructDataField();
        }
      );
    },
  },

  mounted() {
    // this.getFromAPI();
  },
};
</script>
